<template>
    <div>
        <v-card height="100%">
            <v-toolbar flat dense color="blue grey darken-4 white--text" tile>
                <v-icon dark large>mdi-eye-outline</v-icon>
                    <h2
                        class="white--text"
                        style="font-family:'Brush Script MT',cursive;font-name:font-size:20px"
                        >
                        VIEW SIZE AND SERIAL
                    </h2>
            <v-spacer />
                <v-icon dark @click="close()">mdi-close</v-icon>
            </v-toolbar>
        <v-card-text>

    <v-row>
            <v-col v-for="(item, i) in Array30A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[0].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[0]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="25%" class="text-center">{{ body.kairo }}</td>
                    <td width="25%" class="text-center">{{ body.tkva }}</td>
                    <td width="25%" class="text-center">{{ body.contract }}</td>
                    <td width="25%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>


            <!--ANCHOR Array30Ato50ALIST -->

    <v-row>
            <v-col v-for="(item, i) in Array40Ato50A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[1].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[1]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="25%" class="text-center">{{ body.kairo }}</td>
                    <td width="25%" class="text-center">{{ body.tkva }}</td>
                    <td width="25%" class="text-center">{{ body.contract }}</td>
                    <td width="25%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

        <!--ANCHOR Array60Ato100ALIST-->

    <v-row>
            <v-col v-for="(item, i) in Array60Ato75A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[0].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[0]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="25%" class="text-center">{{ body.kairo }}</td>
                    <td width="25%" class="text-center">{{ body.tkva }}</td>
                    <td width="25%" class="text-center">{{ body.contract }}</td>
                    <td width="25%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

        
    <v-row>
            <v-col v-for="(item, i) in Array100A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[2].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[2]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="35%" class="text-center">{{ body.kairo }}</td>
                    <td width="35%" class="text-center">{{ body.tkva }}</td>
                    <td width="30%" class="text-center">{{ body.contract }}</td>
                    <td width="30%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

        <!--ANCHOR Array Array120AList  -->

    <v-row>
            <v-col v-for="(item, i) in Array120A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[3].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[3]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="35%" class="text-center">{{ body.kairo }}</td>
                    <td width="35%" class="text-center">{{ body.tkva }}</td>
                    <td width="30%" class="text-center">{{ body.contract }}</td>
                    <td width="30%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

    <!--ANCHOR Array Array125AList  -->


    <v-row>         
            <v-col v-for="(item, i) in Array125A" :key="i">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center blue grey darken-4 white--text">
                                    {{item[0].title}}
                                </th>
                            </tr>
                            <tr>
                                <th v-for="(header , h) in Object.keys(item[0]).filter(el => el != 'title')" :key="h"  class="text-center">
                                    {{header.toUpperCase()}}
                                </th>
                            </tr>
                        </thead>
                    <tbody>
                <tr v-for="(body, b) in item" :key="b">
                    <td width="35%" class="text-center">{{ body.kairo }}</td>
                    <td width="35%" class="text-center">{{ body.tkva }}</td>
                    <td width="30%" class="text-center">{{ body.contract }}</td>
                    <td width="30%" class="text-center">{{ body.MainBreaker }}</td>
                </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

                </v-card-text>
            </v-card>
        </div>
    </template>

    <script>

    import { bus } from "../main";
    import axios from "axios";
    import moment from "moment";
    import { 
            Array30AList, 
            Array40Ato50AList,
            Array60Ato75AList,
            Array100AList,
            Array120AList,
            Array125AList 
            } from "../components/Reference"

    export default {

    data : () => ({
        Array30A: Array30AList,
        Array40Ato50A: Array40Ato50AList,
        Array60Ato75A: Array60Ato75AList,
        Array100A: Array100AList,
        Array120A: Array120AList,
        Array125A: Array125AList,
        view: {},
    }),

    methods: {
        loadKansensystem() {
        axios.get(`${this.$BaseUrl}/api/getKansensystemData`).then((res) => {
            this.$store.commit("STORE_KANSENSYSTEMINFO", res.data);
            // console.log(res.data)
        });
        },
        close() {
        bus.$emit("CloseDialog", false);
        this.clear();
        },

        clear() {
        this.view = {};
        },
    }, //END OF METHODS
    mounted() {
        this.loadKansensystem();

        bus.$on("viewingDialog", (res) => {
        this.viewingDialog = true;
        Object.assign(this.view, res);
        });
    },
    computed: {
        changeDate() {
        return (str) => {
            if (str != null) {
            return moment(str).format("YYYY-MM-DD");
            }
        };
        },
    },
    };
    </script>

<style>
.select-wrapper {
    display: flex;
}
</style>


