<template>
    <v-container>
        <v-dialog v-model="nonSolarFormDialog" width="1000px">
            <v-card>
                <v-toolbar style="font-size:45px;" color="orange darken-1"><span style="margin-left:35%;color:white;font-family:'Georgia',cursive;font-name:font-size:50px">No Solar Form</span></v-toolbar>

                <v-card-text>
                    <v-row  style="margin-top:2%">
                        <v-col>
                            <v-layout >
                                <v-text-field 
                                    label="Code Number" 
                                    v-model="nonSolarData.code_number" 
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                    label="Customer Name"
                                    v-model="nonSolarData.customerName"  
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                    label="Exhibition Branch Name"
                                    v-model="nonSolarData.exhibitionBranch"  
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                label="Fax No."
                                v-model="nonSolarData.subconFaxNo"  
                                outlined 
                                dense></v-text-field>
                            </v-layout>
                        </v-col>
                    </v-row>

                    <v-row  style="margin-top:2%" class="mt-n6">
                        <v-col>
                            <v-layout >
                                <v-text-field 
                                    label="Joutou Date" 
                                    v-model="nonSolarData.joutou"
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                    label="Less Joutou Date" 
                                    v-model="nonSolarData.lessjoutouDate"
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                    label="New Subcon" 
                                    v-model="nonSolarData.subcon318"
                                    style="margin-right: 10px;" 
                                    outlined 
                                    dense></v-text-field>
                                <v-text-field 
                                    label="Subcon Name"
                                    v-model="nonSolarData.subconName" 
                                    outlined 
                                    dense></v-text-field>
                            </v-layout>
                        </v-col>
                    </v-row>

                    <v-row align="center" class="mt-n9">
                        <v-col v-for="(item, i) in reference" :key="i">
                            <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th
                                    v-for="(header, h) in Object.keys(item[0]).filter(
                                        (el) => el != 'tittle'
                                        )"
                                        :key="h"
                                        class="text-center"
                                    >
                                    {{ header.toUpperCase() }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(body, b) in item" :key="b">
                                    <td width="35%" class="text-center">{{ body.contract }}</td>
                                    <td width="35%" class="text-center">{{ body.amphere }}</td>
                                    <td width="30%" class="text-center">{{ body.mb }}</td>
                                </tr>
                                </tbody>
                            </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>


                    <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                <v-row>
                    <v-col class="d-flex justify-space-around">
                    <center>
                        <span
                        class="white--text mb-3"
                        style="font-family:'Georgia',cursive;font-name:font-size:50px"
                        ><strong>BELOW 100 AMPHERE</strong></span
                        >
                        <br />
                        <span
                        class="white--text mt-n1"
                        style="font-family:'Georgia',cursive;font-name:font-size:50px"
                        ><strong>Proposed Kansen</strong>
                        </span>
                    </center>
                    </v-col>
                </v-row>
                </v-toolbar>
                <v-row align="center">
                <v-col v-for="(item, i) in proposeKansen" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th
                                v-for="(header, h) in Object.keys(item[0])"
                                :key="h"
                                class="text-center"
                                >
                                {{ header.toUpperCase() }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(body, b) in item" :key="b">
                            <td width="35%" class="text-center" id="nonSolarData.lenght_Ksc1">
                                {{ getDivision ? getDivision : "" }}
                            </td>
                            <td width="35%" class="text-center" id="size_ksc1">
                                {{size_ksc1}}
                            </td>
                            <td width="30%" class="text-center" id="mb_ksc1">{{ mb_ksc1 }}</td>
                        </tr>
                        <tr>
                            <td width="35%" class="text-center">電圧降下</td>
                            <td width="35%" class="text-center" id="size_ksc2">
                                {{ formatPrice(comTotal(getDivision, size_ksc1, mb_ksc1)) }}
                            </td>
                            <td width="30%" class="text-center" id="mb_ksc2" style="color: red">
                                {{ com4Val }}
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>


                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                    <v-row align="center">
                        <v-col cols="9" class="d-flex justify-space-around">
                            <v-sheet
                                flat
                                dense
                                color="blue grey darken-4 white--text"
                                style="margin-left: 150px"
                                tile
                            >
                                <strong>幹線</strong>
                            </v-sheet>
                        </v-col>
                        <v-col cols="3" class="d-flex justify-space-around">
                            <v-sheet
                                flat
                                dense
                                color="blue grey darken-4 white--text"
                                style="margin-left: 150px"
                                tile
                            >
                                <strong>アース</strong>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-toolbar>

                <v-row align="center">
                <v-col v-for="(item, i) in mainLine" :key="i">
                    <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th colspan="2"></th>
                            <th
                            v-for="(header, h) in Object.keys(item[0])"
                            :key="h"
                            class="text-center"
                            :style="{ color: header === 'アース棒' ? '#00B0FF' : 'white' }"
                            >
                            {{ header.toUpperCase() }}
                            </th>
                        </tr>
                        </thead>

        <!-- NOTE BODY TABLE FROM KANSEN -->
                        
                        <tbody>
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="2" class="text-center">参考値</td>
                            <td  class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                                <v-combobox
                                    v-if="!existingNoSolar"
                                    v-model="nonSolarData.cc_c1"
                                    @change="updateNonsolar_cc1(nonSolarData.cc_c1)"
                                    :items="selectionCategory"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-combobox>
                                <v-combobox
                                    v-else
                                    v-model="noSolarRequestData.cc_c1"
                                    @change="updateNonsolar_cc1(noSolarRequestData.cc_c1)"
                                    :items="selectionCategory"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-combobox>
                            </td>

                            <td class="text-center" width="13%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="kansen_blankc1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.kansen_blankc1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                
                            </td>

                            <td class="text-center" width="14%">
                                    <v-combobox
                                        v-if="!existingNoSolar"
                                        v-model="nonSolarData.sizeCV_c1"
                                        @change="updateNonsolarSizeCV1(nonSolarData.sizeCV_c1)"
                                        :items="selectionCvt"
                                        hide-details
                                        plain
                                        outlined
                                        dense
                                    ></v-combobox>
                                    <v-combobox
                                        v-else
                                        v-model="noSolarRequestData.sizeCV_c1"
                                        @change="updateNonsolarSizeCV1(noSolarRequestData.sizeCV_c1)"
                                        :items="selectionCvt"
                                        hide-details
                                        plain
                                        outlined
                                        dense
                                    ></v-combobox>


                            </td>
                            <td class="text-center" width="10%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="quantity_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.quantity_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="nonSolarData.Value_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                    @keyup="calculate(nonSolarData.Value_c1)"
                                    @blur="calculate(nonSolarData.Value_c1)" 
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.Value_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                    @keyup="calculate(noSolarRequestData.Value_c1)"
                                    @blur="calculate(noSolarRequestData.Value_c1)" 
                                ></v-text-field>
                            
                            </td>
                            <td class="text-center" width="13%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="wa_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.wa_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="eb_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.eb_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                        </tr>
 

                        <tr>


                            <!-- <td class="text-center">余剰</td> -->
                            <td class="text-center"></td>
                        
                            <td class="text-center" width="17%">
                                <v-text-field
                                    v-if="!existingNoSolar"
                                    v-model="nonSolarData.cc_c2"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="noSolarRequestData.cc_c2"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                                <v-text-field
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                                <v-text-field
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>

                        </tr>
                        </tbody>


<!-- //NOTE START OF DATA FROM SUBCON FIRST TABLE-->
                        <tbody v-if="nonSolarData.first_request_received_date != null">
                        <tr v-for="(body, b) in item" :key="b">
                            <td rowspan="2" class="text-center">参考値</td>
                            <td  class="text-center">電灯</td>
                            <td class="text-center" width="14%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.cc_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>

                            <td class="text-center" width="13%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.kansen_blankc1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>

                            <td class="text-center" width="14%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.sizeCV_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="10%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.quantity_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.Value_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            
                            </td>
                            <td class="text-center" width="13%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.wa_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td rowspan="2" class="text-center" width="20%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.eb_c1"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                        </tr>
 

                        <tr>


                            <!-- <td class="text-center">余剰</td> -->
                            <td class="text-center"></td>
                        
                            <td class="text-center" width="17%">
                                <v-text-field
                                    readonly
                                    style="background-color: #A9A9A9;"
                                    v-model="dataFromSubcon.cc_c2"
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    style="background-color: #A9A9A9;"
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="17%">
                                <v-text-field
                                    style="background-color: #A9A9A9;"
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    style="background-color: #A9A9A9;"
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="12%">
                                <v-text-field
                                    style="background-color: #A9A9A9;"
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>
                            <td class="text-center" width="16%">
                                <v-text-field
                                    style="background-color: #A9A9A9;"  
                                    readonly
                                    hide-details
                                    plain
                                    outlined
                                    dense
                                ></v-text-field>
                            </td>

                        </tr>
                        </tbody>
<!-- NOTE END OF DATA FROM SUBCON FIRST TABLE -->
                        
                    </template>
                    </v-simple-table>
                </v-col>
                </v-row>

<!--NOTE TABLE FOR 太陽光用 -->

                <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                    <v-row align="center">
                        <v-col cols="10" class="d-flex justify-space-around">
                            <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                <strong>太陽光用</strong>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row>
                    <v-col  v-for="(item ,i) in forsunLight" :key="i">
                        <v-simple-table>
                            <template>
                                <thead>
                                    <tr>
                                        <th v-for="(header , h) in Object.keys(item[0])" :key="h" class="text-center">
                                            {{header.toUpperCase()}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr v-for="(body, b) in item" :key="b">
                                        <td rowspan="6" width="1" class="text-center">参考値</td>
                                    </tr>

<!--NOTE 1st row                             -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <v-layout>
                                                <v-checkbox v-model="checkbox1" outlined dense hide-details></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.ic_c2r3"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.ic_c2r3"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout> 
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="checkbox2" outlined dense hide-details ></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.size_c4r3"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.size_c4r3"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="total_5r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.total_5r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="lpi_6r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.lpi_6r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="fl_c7r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.fl_c7r3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center" rowspan="6">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="eb_c1r1"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.eb_c1r1"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>  
                                    </td>
                                    
                        </tr> 


<!--NOTE 2nd Row  -->
                        <tr>
                                        <td width="20%" class="text-center">
                                            <v-layout>
                                                    <v-checkbox v-model="checkbox3" outlined dense hide-details></v-checkbox>
                                                    <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.ic_c2r5"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                    ></v-combobox>
                                                    <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.ic_c2r5"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                    ></v-combobox>
                                            </v-layout> 
                                        </td>
                                        
                                        <td>
                                            <v-layout>
                                                    <v-checkbox v-model="checkbox4" outlined dense hide-details ></v-checkbox>
                                                    <v-combobox
                                                        v-if="!existingNoSolar"
                                                        v-model="nonSolarData.MeterInfo.size_c4r5"
                                                        :items="selectionCvt"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                    ></v-combobox>
                                                    <v-combobox
                                                        v-else
                                                        v-model="noSolarRequestData.size_c4r5"
                                                        :items="selectionCvt"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                    ></v-combobox>
                                            </v-layout>  
                                        </td>


                                        <td width="14%" class="text-center">
                                            <v-text-field
                                                v-if="!existingNoSolar"
                                                v-model="total_5r5"
                                                plain
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                            <v-text-field
                                                v-else
                                                v-model="noSolarRequestData.total_5r5"
                                                plain
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                        </td>

                                        <td width="14%" class="text-center">
                                            <v-text-field
                                                v-if="!existingNoSolar"
                                                v-model="lpi_6r5"
                                                plain
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                            <v-text-field
                                                v-else
                                                v-model="noSolarRequestData.lpi_6r5"
                                                plain
                                                outlined
                                                dense
                                                hide-details
                                            ></v-text-field>
                                        </td>

                                        <td width="14%" class="text-center">
                                            <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="fl_c7r5"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            ></v-text-field>
                                            <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.fl_c7r5"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                            ></v-text-field>
                                        </td>

                        </tr>
                        

<!-- NOTE 3rd Row -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <v-layout>
                                                <v-checkbox v-model="checkbox5" outlined dense hide-details></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.ic_c2r6"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.ic_c2r6"
                                                    :items="selectionKiloWatts"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout> 
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="checkbox6" outlined dense hide-details ></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.size_c4r6"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.size_c4r6"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="total_5r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.total_5r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="lpi_6r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.lpi_6r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="fl_c7r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.fl_c7r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr>
                        

<!-- NOTE 4th Row -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <span>蓄電池自立用</span>
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="checkbox7" outlined dense hide-details ></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.size_c4r7"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.size_c4r7"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="total_5r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.total_5r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="lpi_6r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.lpi_6r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="fl_c7r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.fl_c7r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr>

                        
<!-- NOTE 5th Row -->
                        <tr>
                            <td width="20%" class="text-center">
                                        <span>給電アダプタ</span>
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="checkbox8" outlined dense hide-details ></v-checkbox>
                                                <v-combobox
                                                    v-if="!existingNoSolar"
                                                    v-model="nonSolarData.MeterInfo.newCv55"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                                <v-combobox
                                                    v-else
                                                    v-model="noSolarRequestData.newCv55"
                                                    :items="selectionCvt"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-combobox>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="total_cv55_3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.total_cv55_3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="lpi_cv55_4"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.lpi_cv55_4"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            v-if="!existingNoSolar"
                                            v-model="fl_cv55_5"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            v-model="noSolarRequestData.fl_cv55_5"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr> 

                                </tbody>


<!-- NOTE START OF DATA FROM SUBCON SECOND TABLE -->
                        <tbody v-if="nonSolarData.first_request_received_date != null"> 
                                    <tr v-for="(body, b) in item" :key="b">
                                        <td rowspan="6" width="1" class="text-center">参考値</td>
                                    </tr>

<!--NOTE 1st row                             -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox1" outlined dense hide-details></v-checkbox>
                                                    <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.ic_c2r3"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                    ></v-text-field>
                                        </v-layout> 
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox2" outlined dense hide-details ></v-checkbox>
                                                <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.size_c4r3"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                    ></v-text-field>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.total_5r3"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                        ></v-text-field>
                                    
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.lpi_6r3"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.fl_c7r3"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center" rowspan="6">
                                        <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.eb_c1r1"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                        ></v-text-field>
                                    </td>
                                    
                        </tr> 


<!--NOTE 2nd Row  -->
                        <tr>
                                        <td width="20%" class="text-center">
                                            <v-layout>
                                                    <v-checkbox v-model="dataFromSUbconcheckbox3" outlined dense hide-details></v-checkbox>
                                                    <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.ic_c2r5"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                    ></v-text-field>
                                                   
                                            </v-layout> 
                                        </td>
                                        
                                        <td>
                                            <v-layout>
                                                    <v-checkbox v-model="dataFromSUbconcheckbox4" outlined dense hide-details ></v-checkbox>
                                                    <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.size_c4r5"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                    ></v-text-field>
                                            </v-layout>  
                                        </td>


                                        <td width="14%" class="text-center">
                                            <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.total_5r5"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                            ></v-text-field>
                                        </td>

                                        <td width="14%" class="text-center">
                                            <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.lpi_6r5"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                            ></v-text-field>
                                        </td>

                                        <td width="14%" class="text-center">
                                            <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.fl_c7r5"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                            ></v-text-field>
                                        </td>

                        </tr>
                        

<!-- NOTE 3rd Row -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox5" outlined dense hide-details></v-checkbox>
                                                <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.ic_c2r6"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                ></v-text-field>
                                        </v-layout> 
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox6" outlined dense hide-details ></v-checkbox>
                                                <v-text-field
                                                        readonly    
                                                        v-model="dataFromSubcon.size_c4r6"
                                                        plain
                                                        outlined
                                                        dense
                                                        hide-details
                                                        style="background-color: #A9A9A9;"
                                                ></v-text-field>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.total_5r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.lpi_6r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                        
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.fl_c7r6"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr>
                        

<!-- NOTE 4th Row -->
                        <tr>
                                    <td width="20%" class="text-center">
                                        <span>蓄電池自立用</span>
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox7" outlined dense hide-details ></v-checkbox>
                                                <v-text-field
                                                    readonly
                                                    style="background-color: #A9A9A9;"
                                                    v-model="dataFromSubcon.size_c4r7"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.total_5r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.lpi_6r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.fl_c7r7"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr>

                        
<!-- NOTE 5th Row -->
                        <tr>
                            <td width="20%" class="text-center">
                                        <span>給電アダプタ</span>
                                    </td>
                                    
                                    <td>
                                        <v-layout>
                                                <v-checkbox v-model="dataFromSUbconcheckbox8" outlined dense hide-details ></v-checkbox>
                                                <v-text-field
                                                    readonly
                                                    style="background-color: #A9A9A9;"
                                                    v-model="dataFromSubcon.newCv55"
                                                    plain
                                                    outlined
                                                    dense
                                                    hide-details
                                                ></v-text-field>
                                        </v-layout>  
                                    </td>


                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.total_cv55_3"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.lpi_cv55_4"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    <td width="14%" class="text-center">
                                        <v-text-field
                                            readonly
                                            style="background-color: #A9A9A9;"
                                            v-model="dataFromSubcon.fl_cv55_5"
                                            plain
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </td>

                                    
                                    
                        </tr> 

                                </tbody>

<!-- NOTE END OF DATA FROM SUBCON SECOND TABLE -->  

                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

                </v-card-text>
                
                <center>
                    <v-btn
                        :hidden="this.nonSolarData.first_request_received_date"
                        style="margin-bottom:2%;margin-right:5%" 
                        width="30%" 
                        :color="existingNoSolar ?'warning' : 'success'" 
                        rounded @click="saveNonSolar()">{{existingNoSolar ?'Update' : 'save'}}
                    </v-btn>
                    <v-btn
                        
                        @click="closeNonSolarDialog()"
                        style="margin-bottom:2%" 
                        width="30%" 
                        color="error" 
                        rounded>CLOSE
                    </v-btn>
                </center>
                

            </v-card>
            

        </v-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";
    import { message } from "ant-design-vue";
    import Swal from "sweetalert2";
    import { bus } from "../main";
    import {
        nisetaiArrayProposeKansen,
        ArrayReference,
        ArrayProposeKansen,
        ArraymainLine,
        ArrayforsunLight
    } from "../components/Reference";
        export default {
            data() {
                return {
                    fl_c7r7:'',
                    fl_c7r6:'',
                    fl_c7r5:'',
                    lpi_6r3:'',
                    lpi_6r5:'',
                    lpi_6r6:'',
                    lpi_6r7:'',
                    total_5r3:'',
                    total_5r5:'',
                    total_5r6:'',
                    total_5r7:'',
                    fl_c7r3:'',
                    fl_cv55_5:'',
                    lpi_cv55_4:'',
                    total_cv55_3:'',
                    mb_ksc1:'',
                    size_ksc1:'',
                    wa_c1:'',
                    total_c1 :'',
                    lpi_c1:'',
                    fl_c1:'',
                    eb_c1:'',
                    eb_c1r1:'',
                    kansen_blankc1:'',
                    quantity_c1:'',
                    noSolarRequestData:{},
                    existingNoSolar: false,
                    dataFromSubcon:{},
                    

                    nonSolarData:null,
                    dialogType: false,
                    nonSolarFormDialog: false, 
                    reference: ArrayReference,
                    proposeKasenNisetai :nisetaiArrayProposeKansen,
                    proposeKansen: ArrayProposeKansen,
                    mainLine: ArraymainLine,
                    forsunLight: ArrayforsunLight,

                    selectionKiloWatts: ["3.0KW", "4.0KW", "5.5KW", "5.9KW", "8.0KW", "9.9KW", "KW"],

                  
                    selectionCvt: [
                        'CV8',
                        'CVT14',
                        'CVT22',
                        'CVT38',
                        'CVT60'
                    ],


                    selectionCategory: [
                        "30A",
                        "40A",
                        "50A",
                        "60A",
                        "10KVA",
                        "12KVA",
                        "15KVA",
                        "18KVA",
                        "19KVA",
                        "20KVA",
                        "21KVA",
                        "22KVA",
                        "23KVA",
                        "24KVA",
                        "25KVA",
                    ],
                    
                    checkbox1:false,
                    checkbox2:false,
                    checkbox3:false,
                    checkbox4:false,
                    checkbox5:false,
                    checkbox6:false,
                    checkbox7:false,
                    checkbox8:false,
                  



                    dataFromSUbconcheckbox1:false,
                    dataFromSUbconcheckbox2:false,
                    dataFromSUbconcheckbox3:false,
                    dataFromSUbconcheckbox4:false,
                    dataFromSUbconcheckbox5:false,
                    dataFromSUbconcheckbox6:false,
                    dataFromSUbconcheckbox7:false,
                    dataFromSUbconcheckbox8:false,
                   
                }
            },

            methods: {
                loadDataFromSubcon(code_number){
                    axios({
                        method: 'get',
                        url: `${this.$BaseUrl}/api/getSubconData/${code_number}`,
                        headers: {
                        'x-api-key': this.awsHeaders
                        }
                    }).then(res => {
                        const filteredDataOfOrder = res.data.filter(element => element.code_number == code_number)
                
                        this.dataFromSubcon = Object.assign({}, ...filteredDataOfOrder);
                      
                

                        }).catch(error => {
                        console.error("Error fetching data:", error);
                        });
                
            },
    
                loadUwKansenEditData(codeNumber) {
                    axios({
                                method: 'get',
                                url: `${this.$BaseUrl}/api/getNonSolarData/${codeNumber}`,
                                headers: {
                                    'x-api-key': this.awsHeaders
                                }
                                }).then(res => {
                                    if(res.data.length > 0){
                                        
                                        this.noSolarRequestData = res.data[0]
                                    
                                        this.existingNoSolar = true
                                    
                                    }
                                    
                                    
                                })
                        },
                saveNonSolar(){
                    let nonSolarObject = {
                        code_number : this.nonSolarData.code_number,
                        customerName : this.nonSolarData.customerName,
                        exhibitionBranch : this.nonSolarData.exhibitionBranch,
                        subconFaxNo : this.nonSolarData.subconFaxNo,
                        joutou : this.nonSolarData.joutou,
                        lessjoutouDate : this.nonSolarData.lessjoutouDate,
                        subcon318 : this.nonSolarData.subcon318,
                        subconName : this.nonSolarData.subconName,
                        type : this.nonSolarData.type,
                        cc_c1: this.nonSolarData.cc_c1,
                        Value_c1 : this.nonSolarData.Value_c1,
                        cc_c2 : this.nonSolarData.cc_c2,
                        sizeCV_c1 : this.nonSolarData.sizeCV_c1,
                        ic_c2r3 : this.nonSolarData.MeterInfo.ic_c2r3,
                        size_c4r3 : this.nonSolarData.MeterInfo.size_c4r3,
                        ic_c2r5 : this.nonSolarData.MeterInfo.ic_c2r5,
                        size_c4r5 : this.nonSolarData.MeterInfo.size_c4r5,
                        ic_c2r6 : this.nonSolarData.MeterInfo.ic_c2r6,
                        size_c4r6 : this.nonSolarData.MeterInfo.size_c4r6,
                        size_c4r7 : this.nonSolarData.MeterInfo.size_c4r7,
                        newCv55 : this.nonSolarData.MeterInfo.newCv55,
                        quantity_c1 : this.quantity_c1,
                        kansen_blankc1 : this.kansen_blankc1,
                        wa_c1 : this.wa_c1,
                        eb_c1 : this.eb_c1,
                        total_5r3 : this.total_5r3,
                        lpi_6r3 : this.lpi_6r3,
                        fl_c7r3 : this.fl_c7r3,
                        eb_c1r1 : this.eb_c1r1,
                        total_5r5 : this.total_5r5,
                        lpi_6r5 : this.lpi_6r5,
                        fl_c7r5 : this.fl_c7r5,
                        total_5r6 : this.total_5r6,
                        lpi_6r6 : this.lpi_6r6,
                        fl_c7r6 : this.fl_c7r6,
                        total_5r7 : this.total_5r7,
                        lpi_6r7 : this.lpi_6r7,
                        fl_c7r7 : this.fl_c7r7,
                        total_cv55_3 : this.total_cv55_3,
                        lpi_cv55_4 : this.lpi_cv55_4,
                        fl_cv55_5: this.fl_cv55_5,
                    }

                    let updatedNonSolar = {
                        id : this.noSolarRequestData.id,
                        code_number : this.nonSolarData.code_number,
                        customerName : this.nonSolarData.customerName,
                        exhibitionBranch : this.nonSolarData.exhibitionBranch,
                        subconFaxNo : this.nonSolarData.subconFaxNo,
                        joutou : this.nonSolarData.joutou,
                        lessjoutouDate : this.nonSolarData.lessjoutouDate,
                        subcon318 : this.nonSolarData.subcon318,
                        subconName : this.nonSolarData.subconName,
                        type : this.nonSolarData.type,
                        cc_c1: this.noSolarRequestData.cc_c1,
                        Value_c1 : this.noSolarRequestData.Value_c1,
                        cc_c2 : this.noSolarRequestData.cc_c2,
                        sizeCV_c1 : this.noSolarRequestData.sizeCV_c1,
                        ic_c2r3 : this.noSolarRequestData.ic_c2r3,
                        size_c4r3 : this.noSolarRequestData.size_c4r3,
                        ic_c2r5 : this.noSolarRequestData.ic_c2r5,
                        size_c4r5 : this.noSolarRequestData.size_c4r5,
                        ic_c2r6 : this.noSolarRequestData.ic_c2r6,
                        size_c4r6 : this.noSolarRequestData.size_c4r6,
                        size_c4r7 : this.noSolarRequestData.size_c4r7,
                        newCv55 : this.noSolarRequestData.newCv55,
                        quantity_c1 : this.noSolarRequestData.quantity_c1,
                        kansen_blankc1 : this.noSolarRequestData.kansen_blankc1,
                        wa_c1 : this.noSolarRequestData.wa_c1,
                        eb_c1 : this.noSolarRequestData.eb_c1,
                        total_5r3 : this.noSolarRequestData.total_5r3,
                        lpi_6r3 : this.noSolarRequestData.lpi_6r3,
                        fl_c7r3 : this.noSolarRequestData.fl_c7r3,
                        eb_c1r1 : this.noSolarRequestData.eb_c1r1,
                        total_5r5 : this.noSolarRequestData.total_5r5,
                        lpi_6r5 : this.noSolarRequestData.lpi_6r5,
                        fl_c7r5 : this.noSolarRequestData.fl_c7r5,
                        total_5r6 : this.noSolarRequestData.total_5r6,
                        lpi_6r6 : this.noSolarRequestData.lpi_6r6,
                        fl_c7r6 : this.noSolarRequestData.fl_c7r6,
                        total_5r7 : this.noSolarRequestData.total_5r7,
                        lpi_6r7 : this.noSolarRequestData.lpi_6r7,
                        fl_c7r7 : this.noSolarRequestData.fl_c7r7,
                        total_cv55_3 : this.noSolarRequestData.total_cv55_3,
                        lpi_cv55_4 : this.noSolarRequestData.lpi_cv55_4,
                        fl_cv55_5: this.noSolarRequestData.fl_cv55_5,
                    }
                    let dataForStatus = { codeNumber : this.nonSolarData.code_number,
                                status : 'For Sending' }

                    



                    if(this.existingNoSolar){
                        Swal.fire({
                            icon:'question',
                            title: 'Are you sure?',
                            text:'The data has been saved, would you like to update the existing data?',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            showCancelButton: true,
                            confirmButtonText: 'Update',
                            cancelButtonText : 'Cancel',
                            allowOutsideClick: () => {
                            const popup = this.$swal.getPopup()
                            popup.classList.remove('swal2-show')
                            return false
                        }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                axios({
                                    method: 'post',
                                    url: `${this.$BaseUrl}/api/updateKansenProposalEditForm`,
                                    data: updatedNonSolar,
                                    headers: {
                                        'x-api-key': this.awsHeaders
                                    }
                                }).then(()=>{
                                    message.success({
                                            bottom: '100px',
                                            duration: 2,
                                            maxCount: 3,
                                            text:'Successfuly Update',
                                            });

                                    setTimeout(() => {
                                        location.reload()
                                    }, 2000);
                                })                                        
                            }else{
                                return false
                            }
                        })    
                    }else{
                        Swal.fire({
                            icon:'question',
                            title: 'Are you sure?',
                            text:'Save NoSolar Data?',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            showCancelButton: true,
                            confirmButtonText: 'Save',
                            cancelButtonText : 'Cancel',
                            allowOutsideClick: () => {
                            const popup = this.$swal.getPopup()
                            popup.classList.remove('swal2-show')
                            return false
                    }           
                        }).then((result) => {
                            if (result.isConfirmed) {

                                const inserKansenNonSolar = axios({
                                    method: 'post',
                                    url: `${this.$BaseUrl}/api/insertKansenProposalEditForm`,
                                    data: nonSolarObject,
                                    headers: {
                                        'x-api-key': this.awsHeaders
                                    }
                                });

                                const updateKansenProposalStatus = axios({
                                        method: 'post',
                                        url: `${this.$BaseUrl}/api/addSendingStatus`,
                                        data: dataForStatus,
                                        headers: {
                                            'x-api-key': this.awsHeaders
                                        }
                                    });
                                        Promise.all([inserKansenNonSolar,updateKansenProposalStatus]).then(()=>{
                                            message.success({
                                                        bottom: '100px',
                                                        duration: 2,
                                                        maxCount: 2,
                                                        text: 'Sending Button Enable.',
                                                    });
                                            
                                            setTimeout(() => {
                                            location.reload()
                                            }, 2000);
                                        
                                })
                                    .catch(errors => {
                                        console.error('Promise.all rejected', errors);
                                    });

                                                                
                            }else{
                                return false
                            }
                        })    
                    
                    }
                    // this.loadUwKansenEditData(this.nonSolarData.code_number)
                    
                    console.log(nonSolarObject);

                },
                
                formatPrice(value) {
                        let val = value.toString().replace(",", ".");
                        if (!val || val.charAt(0) === "0") {
                        return value.toFixed(3);
                    } else {
                        const roundedNumber = parseFloat(value.toFixed(2));
                        return roundedNumber.toFixed(2);
                    }
                },

                updateNonsolarSizeCV1(item) {
                    console.log(item);
                    if (item == "CV8") {
                        this.size_ksc1 = 8;
                    } else if (item == "CVT14") {
                        this.size_ksc1 = 14;
                    } else if (item== "CVT22") {
                        this.size_ksc1 = 22;
                    } else if (item == "CVT38") {
                        this.size_ksc1 = 38;
                    } else if (item == "CVT60") {
                        this.size_ksc1 = 60;
                    }
                    // console.log(this.size_ksc1,'LINE3116');
                },

                
                updateNonsolar_cc1(item) {
                    console.log(item,'KVA ITEM');
                    if (item == "30A") {
                        this.noSolarRequestData.kansen_blankc1 = '30A'
                        this.mb_ksc1 = 30;
                        this.kansen_blankc1 = "30A";
                        this.cc_c3 = "30A";
                        this.kansen_blankc3 = "30A";
                        this.mb_ksc1 = "30";
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "40A") {
                        this.noSolarRequestData.kansen_blankc1 = '40A'
                        this.mb_ksc1 = 40;
                        (this.kansen_blankc1 = "40A"),
                        (this.cc_c3 = "40A"),
                        (this.kansen_blankc3 = "40A"),
                        (this.mb_ksc1 = "40");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "50A") {
                        this.noSolarRequestData.kansen_blankc1 = '50A'
                        this.mb_ksc1 = 50;
                        (this.kansen_blankc1 = "50A"),
                        (this.cc_c3 = "50A"),
                        (this.kansen_blankc3 = "50A"),
                        (this.mb_ksc1 = "50");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "60A") {
                        this.noSolarRequestData.kansen_blankc1 = '60A'
                        this.mb_ksc1 = 60;
                        (this.kansen_blankc1 = "60A"),
                        (this.cc_c3 = "60A"),
                        (this.kansen_blankc3 = "60A"),
                        (this.mb_ksc1 = "60");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item== "8KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '40A'
                        this.mb_ksc1 = 40;
                        (this.kansen_blankc1 = "40A"),
                        (this.cc_c3 = "8KVA"),
                        (this.kansen_blankc3 = "40A"),
                        (this.mb_ksc1 = "40");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "10KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '50A'
                        this.mb_ksc1 = 50;
                        (this.kansen_blankc1 = "50A"),
                        (this.cc_c3 = "10KVA"),
                        (this.kansen_blankc3 = "50A"),
                        (this.mb_ksc1 = "50");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "12KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '60A'
                        this.mb_ksc1 = 60;
                        (this.kansen_blankc1 = "60A"),
                        (this.cc_c3 = "12KVA"),
                        (this.kansen_blankc3 = "60A"),
                        (this.mb_ksc1 = "60");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    
                    } else if (item == "15KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '75A'
                        this.mb_ksc1 = 75;
                        (this.kansen_blankc1 = "75A"),
                        (this.cc_c3 = "15KVA"),
                        (this.kansen_blankc3 = "75A"),
                        (this.mb_ksc1 = "75");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "18KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "18KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "19KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "19KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100");
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "20KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "20KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "21KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "21KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "22KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "22KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "23KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "23KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "24KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "24KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    } else if (item == "25KVA") {
                        this.noSolarRequestData.kansen_blankc1 = '100A'
                        this.mb_ksc1 = 100;
                        (this.kansen_blankc1 = "100A"),
                        (this.cc_c3 = "25KVA"),
                        (this.kansen_blankc3 = "100A"),
                        (this.mb_ksc1 = "100"),
                        (this.quantity_c1 = "1"),
                        (this.eb_c1 = "2"),
                        (this.eb_c1r1 = "2"),
                        (this.total_5r7 = "1");
                    }
                },

                comTotal(lenght_Ksc1, size_ksc1, mb_ksc1) {
                    const length = lenght_Ksc1;
                    const size = size_ksc1;
                    const mb = mb_ksc1;
                    return (17.8 * length * mb) / (1000 * size);
                },

                calculate(item) {
                    let sum = 0;
                    let numbers = item.split("+");
                    for (let i = 0; i < numbers.length; i++) {
                        sum += parseInt(numbers[i]);
                    }
                    this.wa_c1 = sum ? sum : "";
                    this.noSolarRequestData.wa_c1 = sum ? sum : "";
                },

                closeNonSolarDialog(){
                    this.nonSolarFormDialog = false
                },

                
            },




            mounted () {
                bus.$on('nonSolarDialog',(res)=>{
                    console.log(res.first_request_received_date,'LINE 1136');
                    this.nonSolarData = res
                    console.log(this.nonSolarData.first_request_received_date);
                    this.nonSolarFormDialog = true

                                                        
                    this.loadUwKansenEditData(res.code_number)
                    this.calculate(this.existingNoSolar ? this.noSolarRequestData.Value_c1: this.nonSolarData.Value_c1)
                    this.updateNonsolarSizeCV1(this.existingNoSolar ? this.noSolarRequestData.sizeCV_c1: this.nonSolarData.sizeCV_c1)
                    this.updateNonsolar_cc1(this.existingNoSolar ? this.noSolarRequestData.cc_c1: this.nonSolarData.cc_c1)
                    this.loadDataFromSubcon(res.code_number)

                    
                });

            },



            computed: {
                getDivision() {
                    if (this.wa_c1 == '') {
                        return parseInt(this.nonSolarData.lenght_Ksc1) == 0;
                    } else {
                        return this.wa_c1 - 5;
                    }
                },

                com4Val() {
                    const comTotalValue = this.comTotal(this.getDivision,this.size_ksc1,this.mb_ksc1);
                        if (comTotalValue >= 2) {
                            return "* Need to use one higher size of CVT *";
                        } else {
                            return "";
                        }
                    },
            },

            watch: {
                
                "fl_c7r3": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox1 = true; 
                        this.checkbox2 = true;
                        } else {
                        this.checkbox1 = false;
                        this.checkbox2 = false;
                        }
                    },
                },

                "fl_c7r5": {
                    immediate: true, 
                    handler(newVal) {
                        
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox3 = true; 
                        this.checkbox4 = true;
                        } else {
                        this.checkbox3 = false;
                        this.checkbox4 = false;
                        }
                    },
                },

                "fl_c7r6": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox5 = true; 
                        this.checkbox6 = true;
                        } else {
                        this.checkbox5 = false;
                        this.checkbox6 = false;
                        }
                    },
                },

                "fl_c7r7": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox7 = true; 
                        // this.checkBox8 = true;
                        } else {
                        this.checkbox7 = false;
                        // this.checkBox8 = false;
                        }
                    },
                },

                "fl_cv55_5": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                            this.checkbox8 = true; 
                        } else {
                        this.checkbox8 = false;
                        }
                    },
                },





                ////NOTE CHEKCBOX FOR UPDATE OF NONSOLAR
                "noSolarRequestData.fl_c7r3": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox1 = true; 
                        this.checkbox2 = true;
                        } else {
                        this.checkbox1 = false;
                        this.checkbox2 = false;
                        }
                    },
                },

                "noSolarRequestData.fl_c7r5": {
                    immediate: true, 
                    handler(newVal) {
                        
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox3 = true; 
                        this.checkbox4 = true;
                        } else {
                        this.checkbox3 = false;
                        this.checkbox4 = false;
                        }
                    },
                },

                "noSolarRequestData.fl_c7r6": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox5 = true; 
                        this.checkbox6 = true;
                        } else {
                        this.checkbox5 = false;
                        this.checkbox6 = false;
                        }
                    },
                },

                "noSolarRequestData.fl_c7r7": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.checkbox7 = true; 
                        // this.checkBox8 = true;
                        } else {
                        this.checkbox7 = false;
                        // this.checkBox8 = false;
                        }
                    },
                },

                "noSolarRequestData.fl_cv55_5": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                            this.checkbox8 = true; 
                        } else {
                        this.checkbox8 = false;
                        }
                    },
                },



                //NOTE CHECKBOX FOR DATA FROM SUBCON

                "dataFromSubcon.fl_c7r3": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.dataFromSUbconcheckbox1 = true; 
                        this.dataFromSUbconcheckbox2 = true;
                        } else {
                        this.dataFromSUbconcheckbox1 = false;
                        this.dataFromSUbconcheckbox2 = false;
                        }
                    },
                },

                "dataFromSubcon.fl_c7r5": {
                    immediate: true, 
                    handler(newVal) {
                        
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.dataFromSUbconcheckbox3 = true; 
                        this.dataFromSUbconcheckbox4 = true;
                        } else {
                        this.dataFromSUbconcheckbox3 = false;
                        this.dataFromSUbconcheckbox4 = false;
                        }
                    },
                },

                "dataFromSubcon.fl_c7r6": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.dataFromSUbconcheckbox5 = true; 
                        this.dataFromSUbconcheckbox6 = true;
                        } else {
                        this.dataFromSUbconcheckbox5 = false;
                        this.dataFromSUbconcheckbox6 = false;
                        }
                    },
                },

                "dataFromSubcon.fl_c7r7": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                        this.dataFromSUbconcheckbox7 = true; 
                        // this.checkBox8 = true;
                        } else {
                        this.dataFromSUbconcheckbox7 = false;
                        // this.checkBox8 = false;
                        }
                    },
                },

                "dataFromSubcon.fl_cv55_5": {
                    immediate: true, 
                    handler(newVal) {
                    
                        if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                            this.dataFromSUbconcheckbox8 = true; 
                        } else {
                        this.dataFromSUbconcheckbox8 = false;
                        }
                    },
                },
        
            },
    }

</script>
<style>
</style>