<template>
    <v-container persistent fluid pa-0>
        <v-card tile style="font-family: Georgia">
            <v-card-title>
                <v-row>
                        <v-btn rounded class="orange black--text" style="margin-top:1%" @click="loadKansensystem()" >KANSEN PROPOSAL
                            <v-badge :content="tableData.length" v-if="tableData.length"  color="red white--text" style="margin-bottom:15%"></v-badge>
                        </v-btn>
                        <v-btn rounded class="orange black--text" style="margin-top:1%; margin-left:1%" @click="FornewKansenRequest()">KANSEN REQUEST
                            <v-badge :content="newRequest.length" v-if="newRequest.length" color="red white--text" style="margin-bottom:15%;margin-left:5%"></v-badge>
                        </v-btn>

                        <v-btn rounded class="orange black--text" style="margin-top:1%; margin-left:1%" @click="getNoRequestData()">No REQUEST
                            <v-badge :content="noRequest.length" v-if="noRequest.length" color="red white--text" style="margin-bottom:15%;margin-left:5%"></v-badge>
                        </v-btn>
                        <!-- <div v-if="order_status == null || order_status == ''">
                        <v-btn rounded class="orange black--text" style="margin-top:5%; margin-left:10%" @click="toggleNewRequest">KANSEN REQUEST</v-btn>
                        </div>
                        <div v-else>
                        <v-btn rounded class="orange black--text" style="margin-top:1%; margin-left:1%" @click="FornewKansenRequest()">New Request
                            <v-badge :content="newRequest.length" v-if="newRequest.length"  color="red white--text" style="margin-bottom:15%;margin-left:5%"></v-badge>
                        </v-btn>
                        <v-btn  rounded class="orange black--text" style="margin-top:1%; margin-left:1%" @click="ForongoingKansenRequest()">On-Going Request
                            <v-badge small :content="ongoingRequest.length" v-if="ongoingRequest.length"    color="red white--text" style="margin-bottom:15%;margin-left:5%"></v-badge>
                        </v-btn>
                        </div> -->
                <v-spacer />
                    <v-col cols="3">
                        <v-text-field 
                            color="orange black--text" 
                            dense 
                            outlined 
                            v-model="search" 
                            append-icon="mdi-magnify" 
                            label="Search" 
                            hide-details 
                            clearable
                            >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>

        <!-- //NOTE KANSEN DATA TABLE -->

        <v-data-table
            height="70vh"
            fixed-header  
            style="font-family: Georgia"
            :headers="headers"
            class="elevation-5"
            hide-default-footer 
            :loading="tableDataLoading"
            :items-per-page="itemsPerPage"
            :page.sync="page" 
            :items="tableData"
            :search="search"
            >

<!-- //ANCHOR  NO. COLUMN -->
        <template v-slot:[`item.id`]="{ item }">{{tableData.indexOf(item) + 1 }}</template>

<!-- //ANCHOR  code number color -->

            <template v-slot:[`item.code_number`]="{item}">

                <span v-show="item.order_status=='New Request'" style="color:red;font-size:13px" class="blinking">New Request</span>
                    <br v-if="item.order_status=='New Request'">
                <span v-show="item.order_status=='On-going'" style="color:red;font-size:13px" class="blinking">On-Going Request</span>
                    <br v-if="item.order_status=='On-going'">
                <span style="font-size:15px"><b>{{ item.code_number }}</b></span><br> 
                    <span v-show="item.houseType=='Wakugumi'" style="color:blue;font-size:12px"><b>WAKU</b></span>
                    <span v-show="item.houseType=='Jikugumi'" style="color:blue;font-size:12px"><b>JIKU</b></span>
                
            </template>

<!-- //ANCHOR Hacchu Finished Date COLUMN-->

        <template v-slot:[`item.hacchu318`]="{item}">
            <v-row align="center" justify="center">
                <span>{{ item.hacchu318 }}</span>
                    
                                                                        
            </v-row>
        </template>

        <template v-slot:[`item.pending`]="{item}">
            <span style="font-size:10px;color:red">{{ item.hacchu_finished_type }}</span> <br>
            <span>{{ item.pending_date }}</span>
            <v-btn v-if="!item.pending_date" style="margin-right: 5px;margin-left: 5px " fab color="red" x-small @click="updatePending('Pending 318' , item.id)">318</v-btn>
            <v-btn  v-if="!item.pending_date" style="margin-right: 5px;margin-left: 5px " fab color="green" x-small @click="updatePending('Pending 657', item.id)">657</v-btn>
        </template>

        <!-- //ANCHOR  NEW TO OLD SUBCON-->

            <template v-slot:[`item.subcon318_old`]="{item}">
                <span :style="{ 'font-size': '15px', 'color': item.subcon318_old === item.subcon318 ? 'black' : 'blue' }"><b>{{ item.subcon318_old }}</b></span><br> 
            </template>
            <template v-slot:[`item.subcon318`]="{item}">
                <span :style="{ 'font-size': '15px', 'color': item.subcon318_old === item.subcon318 ? 'black' : 'red' }"><b>{{ item.subcon318 }}</b></span><br> 
            </template>
        

    //ANCHOR <!-- ACTION COLUMN -->
        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="warning" 
                            @click="editData(item)"
                            >
                            mdi-file-document-edit-outline
                        </v-icon>
                    </v-btn>
                </template><span>View Request</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template  v-slot:activator="{ on, attrs }" >
                <v-icon
                    class="ml-5"
                    v-show="item.kansenProposal_status == 'For Sending'"
                    :hidden="item.sendToSubcon_date" 
                    v-bind="attrs"
                    v-on="on" 
                    @click="sendRequest(item)" 
                    color='green'>mdi-send
                </v-icon>
            </template><span>Click Send Request</span>  
        </v-tooltip>
            <span v-if ="!tableDataLoading" style="font-size:11px"><br>{{ item.first_request_received_date }}</span>
        </template>

        <template v-slot:[`item.nisetai`]="{item}">
            <template v-if="item.nisetai == 2 || item.nisetai == 3">
                <span><b>Yes</b><br></span>
            </template>
            <template v-else>
                <span><b>No</b><br></span>
            </template>
        </template>

    
        <template v-slot:[`item.Kansen_FP`]="{ item }">
            <span v-if="item.Kansen_FP" style="color:rgb(1, 172, 27);font-weight:bold">{{item.Kansen_FP }}</span>
                <span v-else style="font-weight:bold">On-going</span>
        </template>


    <!-- TODO      START NEW REQUEST -->
    <template v-slot:[`item.start_first_request`]="{item}">
                <v-tooltip right>
                    <template  v-slot:activator="{ on }" >
                        <v-btn v-on="on"
                                v-if="item.start_first_request == null && item.first_request_received_date != null "
                                @click="firstRequestStartDate(item)" 
                                color='success' 
                                style="align:center"
                                small
                                rounded 
                            >start
                        </v-btn>
                        <span>{{item.start_first_request}}</span>
                    </template>Click to Start Request   
                </v-tooltip>  
    </template>

    <!-- TODO      FINISH NEW REQUEST -->
            <template v-slot:[`item.finish_first_request`]="{item}">
                <v-tooltip right>
                    <template  v-slot:activator="{ on }" >
                        <v-btn v-on="on"
                                v-if="item.finish_first_request==null && item.start_first_request!=null " 
                                @click="firstRequestFinishDate(item)" 
                                color='success' 
                                style="align:center"
                                small
                                rounded 
                            >FINISH
                        </v-btn>
                        <span >{{item.finish_first_request}}</span>
                    </template>Click to Finished Request    
                </v-tooltip>  
            </template>
    

//ANCHOR  <!-- KANSEN CHECKLIST COLUMN -->
        <!-- <template v-slot:[`item.UwkansenChecklist`]="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="pink" @click="kanseChecklist(item)">mdi-playlist-check</v-icon>
                    </v-btn>
                </template><span>Edit</span>
            </v-tooltip>
        </template>         -->

->

    </v-data-table>
        <div class="text-center pt-1" style="font-size:12px;" >
            <v-pagination color="blue grey darken-4" v-model="page" :length="pageCount" :total-visible="itemsPerPage" right></v-pagination>
        </div>
            <v-footer>
            <v-spacer/>
            <v-btn v-if="account.role == 'Kansen User'" rounded class="success" style="margin-right:1%; margin-left:1%" @click="ViewfinishedPlan()">View Finish Plan</v-btn>
            <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn rounded style="font-family: Georgia; margin-right:8px" @click="viewDialog()" color="orange" v-bind="attrs" v-on="on">
                            <v-icon>mdi-eye-outline</v-icon>
                                SIZE AND SERIAL
                        </v-btn>
                    </template>
                    <span>VIEW SIZE AND SERIAL</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn rounded style="font-family: Georgia"  color="orange" v-bind="attrs" v-on="on" v-if="account.role == 'System Admin' || account.role == 'Kansen Admin'" @click="openRegisterDialog()">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                                REGISTER
                        </v-btn>
                    </template>
                    <span>ADD RECORD</span>
                </v-tooltip>
                
            </v-footer>
        <div>
            <v-dialog v-model="addDialog" width="400px" persistent>
                <template>
                    <registerDialogVue></registerDialogVue>
                </template>
            </v-dialog>
        </div>
        <div>
            <editDialogVue Type="Edit"></editDialogVue>
        </div>
        <div>
            <v-dialog v-model="viewingDialog" width="800px" persistent>
                <template>
                    <viewingDialogVue></viewingDialogVue>
                </template>
            </v-dialog>
        </div>

        <div>
            <nonSolarDialog ></nonSolarDialog>
        </div>
        <div class="lds-spinner" v-if="this.loadingProposalFrom">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
       
    </v-container>
</template>

<script>
// import _ from 'lodash';
import moment from 'moment';
import { bus } from '../main'
import nonSolarDialog from '../components/NonSolarForm.vue'
import registerDialogVue from '../components/registerDialog.vue'
import editDialogVue from '../components/editDialog.vue'
import viewingDialogVue from '../components/viewingDialog.vue'
import axios from "axios";
import Swal from "sweetalert2";
import store from "../store/index";
export default {
    components: {
        registerDialogVue,
        editDialogVue,
        viewingDialogVue,
        nonSolarDialog
    },


    data()  {
        
    return {
        
        // awsHeaders : process.env.VUE_APP_AWS_HEADERS,
        nisetai_kansen_blankc1 :null,
        loadingProposalFrom:false,
        editDataFrom54:{},
        kansenDataFrom54:[],
        noRequest: [],
        dataOfKansenEdit:[],
        oldItem:{},
        newitem:{},
        rowindex:-1,
        editColumn:'',
        ip:'',
        headers: [
                { text: "No.",                                  value: "id",                              align: "center",          width:"80px",      class: "white--text",},
                { text: "Action",                               value: "actions",                         align: "center",          width:"150px",     class: "white--text",},
                { text: "Nisetai",                              value: "nisetai",                         align: "center",          width:"200px",     class: "white--text",},
                { text: "Code Number",                          value: "code_number",                     align: "center",          width:"200px",     class: "white--text",},
                { text: "UW Checkbox Finished Date",            value: "Checkbox_FP",                     align: "center",          width:"245px",     class: "white--text",},
                { text: "Hacchu Finished Date",                 value: "hacchu318",                       align: "center",          width:"200px",     class: "white--text",},
                { text: "Pending 318 / 657 ",                   value: "pending",                         align: "center",          width:"200px",     class: "white--text",},
                { text: "Due Date in PCMS",                     value: "Kansen_DD",                       align: "center",          width:"200px",     class: "white--text",},
                { text: "Joutou Date in PCMS",                  value: "joutou",                          align: "center",          width:"200px",     class: "white--text",},
                { text: "Days Left from Joutou",                value: "daysLeft",                        align: "center",          width:"200px",     class: "white--text",},
                { text: "Old Subcon",                           value: "subcon318_old",                   align: "center",          width:"200px",     class: "white--text",},
                { text: "New Subcon",                           value: "subcon318",                       align: "center",          width:"200px",     class: "white--text",},
                { text: "Specification",                        value: "specification",                   align: "center",          width:"200px",     class: "white--text",},          
                { text: "Start New Request",                    value: "start_first_request",             align: "center",          width:"210px",     class: "white--text",},
                { text: "Finished New Request",                 value: "finish_first_request",            align: "center",          width:"220px",     class: "white--text",},
            ],
        subconHeaders : [
            { text: "Date send to subcon",                  value: "sendToSubcon_date",              align: "center",          width:"180px",     class: "white--text",},
            { text: "PCMS Status",                          value: "Kansen_FP",                     align: "center",          width:"200px",     class: "white--text",}
        ],

        search: '',
        page: 1,
        itemsPerPage: 10,
        num : 1,
        addDialog : false,
        addData: {},
        editDialog: false,
        nonSolarFormDialog:false,
        edit: {},
        tableData : [],
        timestamp :'',
        tab : null,
        forEdit:{},
        dateNow : moment().format('YYYY-MM-DD'),
        order_status : '',
        // FilteredButton : '',
        UserName:'',
        viewingDialog : false,
        tableDataLoading : false,
        allRequest:[],
        newRequest:[],
        ongoingRequest:[],
        houseType : ''
    };
    },

    watch: {
        addDialog(){
            this.loadKansensystem()
        }
        
    },

    methods: {
        

    // async sendEmail(type , id){
    //         let obj = {
    //             type : type,
    //             id : id ,
    //         }
    //         try {
    //             await axios.post(`${this.$BaseUrl}/api/updateHacchuType`  , obj)
    //             location.href = `http://hrdapps54/electrical2/data/sendingEmailKansen.php?type=${type}` 
    //             this.loadKansensystem()
    //         } catch (error) {
    //             alert(error.message)
    //         }
        
    // },

    updatePending(type , id) {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        const date = `${year}-${month}-${day}`;

        let obj = {
                type : type,
                id : id ,
                dateToday : date
            }

            const pendingType = axios({
                        method:'post',
                        url:`${this.$BaseUrl}/api/updateHacchuType`,
                        data:obj,
                        headers: {
                            'x-api-key' : this.awsHeaders
                        }
                    }) 
            const pendingDate = axios({
                method:'post',
                url:`${this.$BaseUrl}/api/updatePendingDate`,
                data:obj,
                headers: {
                    'x-api-key' : this.awsHeaders
                }
            }) 
            
            Promise.all([pendingType,pendingDate]).then(() => {
                        Swal.fire({
                            toast: true,
                            position: "center-start",
                            icon: 'success',
                            title: 'Pending data Updated!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    setTimeout(() => {
                    location.reload()
            }, 1000);

        },

   
    
    firstRequestStartDate(item){
        // console.log(item,'THIS IS TEST');
    
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = moment(date + ' ' + time).format('YYYY-MM-DD');

            let objectToUpdate = { start_first_request : dateTime, id:item.id }
            Swal.fire({
                icon:'question',
                title: item.code_number,
                html: 'Start Processing Order?',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method:'post',
                        url:`${this.$BaseUrl}/api/startFirstRequest`,
                        data:objectToUpdate,
                        headers: {
                            'x-api-key' : this.awsHeaders
                        }
                    }) .then(() => {
                    Swal.fire({
                                    toast: true,
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Request Started',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    customClass: {
                                    popup: 'my-toast'
                                }
                                    })
                    })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                }
            })
    },    

    firstRequestFinishDate(item){
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = moment(date + ' ' + time).format('YYYY-MM-DD');
            let objectToFinish = { finish_first_request : dateTime, id:item.id }

            Swal.fire({
                icon:'question',
                title: item.code_number,
                html: 'Finish Processing Order?',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method:'post',
                        url:`${this.$BaseUrl}/api/finishFirstRequest`,
                        data:objectToFinish,
                        headers: {
                            'x-api-key' : this.awsHeaders
                        }
                    }) .then(() => {
                    Swal.fire({
                                    toast: true,
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Request Started',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    customClass: {
                                    popup: 'my-toast'
                                }
                                    })
                        })
                        setTimeout(() => {
                            window.location.reload()
                        },2000);
                        
                }
            })
    },

    sendRequest(item){
        // console.log(item,'item from sending btn');
            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');

            const date = `${year}-${month}-${day}`;
    

        let requestData = { send_request :date, 
                            send_request_by : this.UserName , 
                            code : item.code_number ,
                            }

        let dataForsubconMain = {
            code_number :item.code_number,
            dateSent : date
        }
        
            const proposalData = this.dataOfKansenEdit.filter(el=> el.code_number == item.code_number)


            const updateKansenSystemDataAfterSending = axios({
                method: 'post',
                url: `${this.$BaseUrl}/api/sendingRequest`,
                data: requestData,
                headers: {
                    'x-api-key': this.awsHeaders
            }
            });

            const sendCodenumberInSubcon = axios({
                method: 'post',
                url: `${this.$BaseUrl}/api/insertCodeNumberInSubcon`,
                data: dataForsubconMain,
                headers: {
                    'x-api-key': this.awsHeaders
                }
            });

            const sendProposalToSubconOrderTable = axios({
                method: 'post',
                url: `${this.$BaseUrl}/api/insertIntoSubconOrder`,
                data: proposalData[0],
                headers: {
                    'x-api-key': this.awsHeaders
                }
            });

            const insertNisetaiData = axios({
                                method: 'post',
                                url: `${this.$BaseUrl}/api/insertNisetaiData`,
                                data: proposalData[0],
                                headers: {
                                    'x-api-key': this.awsHeaders
                                }
                });
                
        Swal.fire({
            title:'Are you sure you want to send?',
            text: `Code Number ${item.code_number}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#05C90A',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            width: '400px',
            height: '200px'
            }).then((result) => {
                if (result.isConfirmed) {
                    if(item.nisetai > 1){
                        Promise.all([updateKansenSystemDataAfterSending,sendCodenumberInSubcon,sendProposalToSubconOrderTable,insertNisetaiData])
                        .then(() => {
                            Swal.fire({
                            toast: true,
                            position: "top",
                            icon: 'success',
                            title: 'Send Successful!',
                            showConfirmButton: false,
                            timer: 4000,
                            });
                            this.loadKansensystem();
                            this.editDialog = false
                            // setTimeout(() => {
                            //     location.reload()
                            // }, 4000);
                            
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    }else{
                        Promise.all([updateKansenSystemDataAfterSending,sendCodenumberInSubcon,sendProposalToSubconOrderTable])
                        .then(() => {
                            Swal.fire({
                            toast: true,
                            position: "top",
                            icon: 'success',
                            title: 'Send Successful!',
                            showConfirmButton: false,
                            timer: 4000,
                            });
                            this.loadKansensystem();
                            this.editDialog = false
                            // setTimeout(() => {
                            //     location.reload()
                            // }, 4000);
                                    
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    }
                       
                }
            })
    },

        //for new request
            
    loadKansensystem(){
                axios({
                    method: 'get',
                    // url: `${this.$BaseUrl}/api/getTemporaryKansenPageData`,
                    url: `${this.$BaseUrl}/api/getCombinedDataOfKansen`,

                    headers: {
                        'x-api-key': this.awsHeaders
                    }
                    }).then(res => {
                        const withoutNewRequestData = res.data.filter(item => item.order_status != 'New Request' && item.order_status != 'On-going' && !item.sendToSubcon_date)
                        this.tableData = withoutNewRequestData
                        const newReqAndOngoing = res.data.filter(item => item.order_status == 'New Request' || item.order_status == 'On-going' && item.sendToSubcon_date)
                        this.newRequest = newReqAndOngoing

                        const noRequestFromSubcon = res.data.filter(item => item.order_status == 'No Request' && item.sendToSubcon_date)
                        this.noRequest = noRequestFromSubcon
                    })

                    },
            
    async FornewKansenRequest(){
        this.tableDataLoading = true
        const headers = { 'x-api-key' : this.awsHeaders };
        let newRequest = await axios.get(`${this.$BaseUrl}/api/getNewRequest`,{headers})
        this.tableData = newRequest.data
        
        this.tableDataLoading = false

    
        
        for(let status of this.subconHeaders){
            let i = this.headers.indexOf(status)
            if(i > 0){
                this.headers.splice(i , 1)
            }
        }

        //NOTE Add column in kansen request

        let indexToInsert = 11
        for(let status of this.subconHeaders){
            this.headers.splice(indexToInsert , 0 , status)
            indexToInsert = indexToInsert + 1
        }

    
    },



    async getNoRequestData(){
        this.tableDataLoading = true
        const headers = { 'x-api-key' : this.awsHeaders };
        let noRequestObj = await axios.get(`${this.$BaseUrl}/api/getNoRequest`,{headers})
        this.tableData = noRequestObj.data
        
        this.tableDataLoading = false

    
        
        for(let status of this.subconHeaders){
            let i = this.headers.indexOf(status)
            if(i > 0){
                this.headers.splice(i , 1)
            }
        }

        //NOTE Add table in kansen request

        let indexToInsert = 11
        for(let status of this.subconHeaders){
            this.headers.splice(indexToInsert , 0 , status)
            indexToInsert = indexToInsert + 1
        }

    
    },







    //NOTE selectEditColumn(index,column
        //enabling edit in every column or the function behind double click
    selectEditColumn(index,column){
            this.rowindex = index
            this.editColumn = column
            setTimeout(function () { 
            document.getElementById(column)  
        }, 100);        
    },

    getRenamingdays(item){
        const now = new Date().getTime();
        const joutou = new Date(item).getTime();
        // DATEDIFF(a.joutou, CURDATE()) as Days_left
        const timeleft =  joutou - now;
        const days   = Math.floor( timeleft / (1000 * 60 * 60 * 24));
        return days
    },

    //NOTE kansen checklist

        // kanseChecklist(item){
        //     window.open(`http://hrdapps54/kansenchecklist/index.php?code_number=${item.code_number}` , '_blank')
        // },






   async editData(item){
       
        
    
            const headers = { 'x-api-key' : this.awsHeaders };
            let dataFromHacchu = await axios.get(`${this.$BaseUrl}/api/hacchuData/${item.code_number}`,{headers})
                this.loadingProposalFrom = true
            let nisetai_size_ksc1= null
            let nisetai_mb_ksc1 = null
           
            // console.log(dataFromHacchu.data[0].KyuudenAdaptor,'TESSST LINE 761');

                setTimeout(() => {
                    let MeterInfoObject = [{
                        ic_c1r7: dataFromHacchu.data[0].MeterInfo_ic_c1r7,
                        ic_c2: dataFromHacchu.data[0].MeterInfo_ic_c2,
                        ic_c2r2: dataFromHacchu.data[0].MeterInfo_ic_c2r2,
                        ic_c2r3: dataFromHacchu.data[0].MeterInfo_ic_c2r3,
                        ic_c2r4: dataFromHacchu.data[0].MeterInfo_ic_c2r4,
                        ic_c2r5: dataFromHacchu.data[0].MeterInfo_ic_c2r5,
                        ic_c2r6: dataFromHacchu.data[0].MeterInfo_ic_c2r6,
                        newCv55: dataFromHacchu.data[0].MeterInfo_newCv55,
                        sizeCV_c4r4: dataFromHacchu.data[0].MeterInfo_sizeCV_c4r4,
                        size_c4r2: dataFromHacchu.data[0].MeterInfo_size_c4r2,
                        size_c4r3: dataFromHacchu.data[0].MeterInfo_size_c4r3,
                        size_c4r5: dataFromHacchu.data[0].MeterInfo_size_c4r5,
                        size_c4r6: dataFromHacchu.data[0].MeterInfo_size_c4r6,
                        size_c4r7: dataFromHacchu.data[0].MeterInfo_size_c4r7,
                        size_cvt1: dataFromHacchu.data[0].MeterInfo_size_cvt1,
                    }];

                    if(dataFromHacchu.data[0].KyuudenAdaptor){
                        dataFromHacchu.data[0].total_cv55_3 = 1
                        dataFromHacchu.data[0].lpi_cv55_4 = dataFromHacchu.data[0].KyuudenAdaptor
                        dataFromHacchu.data[0].fl_cv55_5 = dataFromHacchu.data[0].KyuudenAdaptor
                    }

                    if (dataFromHacchu.data[0].sizeCV_c1 == "CV8") {
                        nisetai_size_ksc1 = 8;
                    
                    } else if (dataFromHacchu.data[0].sizeCV_c1 == "CVT14") {
                        nisetai_size_ksc1 = 14;
                    
                    } else if (dataFromHacchu.data[0].sizeCV_c1 == "CVT22") {
                        nisetai_size_ksc1 = 22;
                    
                    } else if (dataFromHacchu.data[0].sizeCV_c1 == "CVT38") {
                        nisetai_size_ksc1 = 38;
                    
                    } else if (dataFromHacchu.data[0].sizeCV_c1 == "CVT60") {
                        nisetai_size_ksc1 = 60;
                    
                    }




                    if (dataFromHacchu.data[0].cc_c1 == "30A") {
                        nisetai_mb_ksc1 = 30; 
                        nisetai_mb_ksc1 = "30"
                    } else if (dataFromHacchu.data[0].cc_c1 == "40A") {
                       nisetai_mb_ksc1 = 40;
                    
                        (nisetai_mb_ksc1 = "40")
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "50A") {
                       nisetai_mb_ksc1 = 50;
                        
                        (nisetai_mb_ksc1 = "50")
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "60A") {
                       nisetai_mb_ksc1 = 60;
                    
                        (nisetai_mb_ksc1 = "60")
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "8KVA") {
                       nisetai_mb_ksc1 = 40;
                      
                    
                        (nisetai_mb_ksc1 = "40")
                        this.nisetai_kansen_blankc1 = '40A';
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "10KVA") {
                       nisetai_mb_ksc1 = 50;
                       
                        
                        (nisetai_mb_ksc1 = "50")
                        this.nisetai_kansen_blankc1 = '50A';
                    } else if (dataFromHacchu.data[0].cc_c1 == "12KVA") {
                       nisetai_mb_ksc1 = 60;
                    
                        (nisetai_mb_ksc1 = "60")
                        this.nisetai_kansen_blankc1 = '60A';
                    
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "15KVA") {
                       nisetai_mb_ksc1 = 75;
                    
                        (nisetai_mb_ksc1 = "75")
                        this.nisetai_kansen_blankc1 = '75A';
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "18KVA") {
                       nisetai_mb_ksc1 = 100;
                    
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "19KVA") {
                       nisetai_mb_ksc1 = 100;
                        
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "20KVA") {
                       nisetai_mb_ksc1 = 100;
                    
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "21KVA") {
                       nisetai_mb_ksc1 = 100;
                    
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "22KVA") {
                       nisetai_mb_ksc1 = 100;
                        
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "23KVA") {
                       nisetai_mb_ksc1 = 100;
                    
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                    
                    } else if (dataFromHacchu.data[0].cc_c1 == "24KVA") {
                       nisetai_mb_ksc1 = 100;
                        
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                        
                    } else if (dataFromHacchu.data[0].cc_c1 == "25KVA") {
                        nisetai_mb_ksc1 = 100;
                    
                        (nisetai_mb_ksc1 = "100")
                        this.nisetai_kansen_blankc1 = '100A';
                    
                    }


                    if(dataFromHacchu.data[0].nisetaiCc_c1){
                        if (dataFromHacchu.data[0].nisetaiCc_c1 == "8KVA") {
                            
                            this.nisetai_kansen_blankc1 = '40A';
                        
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "10KVA") {
                        
                            this.nisetai_kansen_blankc1 = '50A';
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "12KVA") {
                            
                            this.nisetai_kansen_blankc1 = '60A';
                        
                        
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "15KVA") {
                            
                            this.nisetai_kansen_blankc1 = '75A';
                        
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "18KVA") {
                            
                            this.nisetai_kansen_blankc1 = '100A';
                            
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "19KVA") {
                            
                            this.nisetai_kansen_blankc1 = '100A';
                            
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "20KVA") {
                        
                            this.nisetai_kansen_blankc1 = '100A';
                            
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "21KVA") {
                        
                            this.nisetai_kansen_blankc1 = '100A';
                            
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "22KVA") {
                            
                            this.nisetai_kansen_blankc1 = '100A';
                        
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "23KVA") {
                        
                            this.nisetai_kansen_blankc1 = '100A';
                        
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "24KVA") {
                            
                            this.nisetai_kansen_blankc1 = '100A';
                            
                        } else if (dataFromHacchu.data[0].nisetaiCc_c1 == "25KVA") {
                
                            this.nisetai_kansen_blankc1 = '100A';
                        
                        }
                    }

                
                    let nonSocketObject = Object.assign(item,{
                            Value_c1 : dataFromHacchu.data[0].Value_c1,
                            wa_c1 : dataFromHacchu.data[0].wa_c1,
                            lenght_Ksc1 : dataFromHacchu.data[0].lenght_Ksc1,
                            lpi_6r6 : dataFromHacchu.data[0].lpi_6r6,
                            lpi_6r7 : dataFromHacchu.data[0].lpi_6r7,
                            fl_c7r6 : dataFromHacchu.data[0].fl_c7r6,
                            fl_c7r7 : dataFromHacchu.data[0].fl_c7r7,
                            nisetaiCc_c1 : dataFromHacchu.data[0].nisetaiCc_c1,
                            nisetai_kansen_blankc1 : this.nisetai_kansen_blankc1,
                            cc_c1 : dataFromHacchu.data[0].cc_c1,
                            sizeCV_c1 : dataFromHacchu.data[0].sizeCV_c1,
                            type : dataFromHacchu.data[0].type,
                            cc_c2 : dataFromHacchu.data[0].cc_c2,
                            total_cv55_3:dataFromHacchu.data[0].total_cv55_3,
                            lpi_cv55_4:dataFromHacchu.data[0].lpi_cv55_4,
                            fl_cv55_5:dataFromHacchu.data[0].fl_cv55_5,
                            MeterInfo : MeterInfoObject[0],
                            wattsDetails : dataFromHacchu.data[0].wattsDetails,
                            sizeCV_c2 : dataFromHacchu.data[0].sizeCV_c2,
                            quantity_c2: dataFromHacchu.data[0].quantity_c2 == null ? 1 : dataFromHacchu.data[0].quantity_c2,
                            nisetai_size_ksc1 : nisetai_size_ksc1,
                            nisetai_mb_ksc1 :nisetai_mb_ksc1,
                        
                    })

                
                    if(item.type == 'No Solar'){
                        bus.$emit('nonSolarDialog',nonSocketObject)
                        // console.log(nonSocketObject,"FOR SOLAR");
                    
                        this.loadingProposalFrom = false;
                    }else{
                        bus.$emit('editDialog',nonSocketObject)
                        // console.log(item,'LINE 922');
                    
                        this.loadingProposalFrom = false;
                    }
                        
                    }, 1000);

                    


    },


    viewDialog(){
        this.viewingDialog = true
    },
        
    openRegisterDialog(){
        this.addDialog = true
    },
    



    deleteData(item) {

        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let dateTime = date;


        Swal.fire({
            imageUrl: "http://hrdapps58:8888/ftp/SystemLogo/Anjhoe/delete-351.png",
            imageWidth: 300,
            imageHeight: 100,
            imageAlt: "Custom image",
            title: "Are you sure you want to delete?",
            text: `Code Number ${item.code_number}`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => { 
            if (result.isConfirmed) {
                axios.post(`${this.$BaseUrl}/api/DeleteData`,{ deleted_date : dateTime , deleted_by : this.ip , id: item.id}).then(res => {
                    if(res.data== 'Success'){
                        this.loadKansensystem();
                        Swal.fire({
                            toast: true,
                            position: "center-end",
                            icon: 'success',
                            title: 'Deleted Successfully!',
                            text: `Code Number ${item.code_number}`,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                })
            }
        })
    },//end of delete

    ViewfinishedPlan(){
        this.$router.push("/Finishedplan")
    },
    

    loadDeletedUsers(){
    axios({
                method: 'get',
                url: `${this.$BaseUrl}/api/getallDeletedUser`,
                headers: {
                'x-api-key': this.awsHeaders
                }
            }).then(res => {
                this.deletedUsersData = res.data
            //   console.log(this.deletedUsersData,'push');
            
            })

    },
    loadKansenProposal(){

        axios({
                method: 'get',
                url: `${this.$BaseUrl}/api/getKansenProposalData`,
                headers: {
                'x-api-key': this.awsHeaders
                }
            }).then(res => {
                this.dataOfKansenEdit = res.data 
            })
    },





    }, //END OF METHODS
    
    mounted() {
        
        this.loadKansenProposal()



        this.loadKansensystem()
        // this.ForAllkansenrequest()

        this.UserName = store.state.account.name

        bus.$on('CloseDialog' , res => {
            this.addDialog = res
            this.viewingDialog = res
        })

        
    },

    computed: {
        pageCount(){
            let a = Math.ceil(this.tableData.length / this.itemsPerPage)
            return a
        },
    },
}

</script>
<style>
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
    }

.blinking {
    animation: blink 1s infinite;
    }

.classT tr:hover {
    background-color: #eeeeee;
}

.classT {
    border-left: 2px solid gray;
}

.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        background-color: #1F2833;
        color: white;
    }

.classT, th, td, tr {
    border: 1px solid rgb(221, 140, 0);
}

.thStyle{
    background-color:rgb(26, 32, 38);
}

.swal2-toast {
  width: 300px; /* Change the width as per your requirement */
  background-color: #9b0000; /* Change the color as per your preference */
}
.sentRequest_color {
    /* background-color: #c36308a2; */
    color: rgba(255, 0, 0, 0.777);
    font-weight: bold
}





.lds-spinner {
  color: official;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(246, 119, 0);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
